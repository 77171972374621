.slider {
  position: relative;
  min-height: 175px;
}

.slider .slide {
  text-align: center;
}

.slider .slide img {
  max-width: 100%;
  vertical-align: top;
}

.slider .button_prev,
.slider .button_next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
}

.slider .button_prev {
  left: 20px;
}

.slider .button_next {
  right: 20px;
}

.slider .thumbs {
  position: relative;
}

.slider .bullet {
  width: 7px;
  height: 7px;
  display: inline-block;
  border-radius: 100%;
  background: #FFF;
  margin: 0 3px 28px;
  position: relative;
}

.slider .bullet::before {
  content: "";
  position: absolute;
  top: -4px;
  bottom: -4px;
  right: -2px;
  left: -4px;
  background: rgb(0 0 0 / 35%);
  z-index: -1;
}

.slider .bullet:first-child::before {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  left: -5px;
}

.slider .bullet:last-child::before {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  right: -5px;
}

.slider .bullet_active {
  background: #FFCF28;
}

.arrows_wrapper {
  display: none;
}

.wide .slide {
  object-fit: cover;
  object-position: center;
}

/* media for wide banners */
@media (max-width: 1439px) {
  .wide .arrows_wrapper {
    max-width: 900px;
  }
}

@media (max-width: 1199px) {
  .slider .button_prev {
    left: 10px;
  }

  .slider .button_next {
    right: 10px;
  }

  .wide .arrows_wrapper {
    max-width: 840px;
  }

  .wide .bullet {
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) {
  .slider .arrows_wrapper {
    max-width: 890px;
  }
}

@media (min-width: 991px) {
  .slider .bullet {
    margin: 0 3px 31px;
  }
}

@media (min-width: 1439px) {
  .slider .bullet {
    margin: 0 3px 36px;
  }

  .arrows_wrapper {
    max-width: 1400px;
  }
}

@media (max-width: 991px) {
  .slider .arrows_wrapper {
    max-width: 710px;
  }
}

@media (max-width: 768px) {
  .slider .bullet {
    margin: 0 3px 20px;
  }
}

@media (max-width: 640px) {
  .slider .bullet {
    margin: 0 3px 15px;
  }

  .arrows_wrapper {
    display: none;
  }
}

@media (max-width: 575px) {
  .slider .bullet {
    width: 6px;
    height: 6px;
    margin: 0 2px 11px;
  }

  .slider .bullet::before {
    top: -4px;
    bottom: -4px;
    right: -2px;
    left: -2px;
  }
}

@media (max-width: 480px) {
  .slider .bullet {
    margin: 0 2px 8px;
  }

  .slider .bullet::before {
    right: 0;
    left: -4px;
  }
}

@media (max-width: 425px) {
  .slider .bullet {
    margin: 0 2px 5px;
  }
}

@media (max-width: 375px) {
  .slider .bullet {
    margin: 0 2px 3px;
  }

  .slider .bullet::before {
    top: -3px;
    bottom: -3px;
  }
}
