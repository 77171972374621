.mobile_slide_inner {
  margin: 0 auto;
  max-width: 1100px;
}

.desktop_slide_inner {
  margin: 0 auto;
}

.desktop_slide_inner > a > div {
  width: 100%;
}

.desktop_slide_inner > div {
  width: 100%;
}

@media (max-width: 1439px) {
  .mobile_slide_inner {
    max-width: 1000px; /* delete this style for wide banners  */
  }

  .desktop_slide_inner {
    max-width: none;
  }
}
